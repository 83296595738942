.KanbanColumn {
  width: 12%;
  min-width: 160px;
  max-width: 200px;
  text-align: center;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  min-height: 700px;
  margin: 0.3rem;
}

.KanbanColumns-tasks-container {
  display: flex;
  flex-direction: column;
  //justify-content: center;
  align-items: center;
  height: 100%;
  flex-grow: 1;
}

.KanbanColumn-name {
  color: black;
  font-size: 0.7rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  letter-spacing: 0.1rem;
  height: 1rem;
}

.KanbanColumn-numbers {
  color: black;
  font-size: 0.7rem;
  margin-top: 0;
  margin-bottom: 0;
}

.KanbanColumn-footer {
  padding: 1rem 0;
}

.KanbanColumn-btn-add {
  background-color: white;
  opacity: 0.8;
  padding: 0.3rem;
  border: 1px solid white;
  border-radius: 10px;
  cursor: pointer;
}

.KanbanColumn-btn-add:hover {
  opacity: 1;
}

.KanbanColumn-main {
  height: 100%;
}