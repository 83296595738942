.plusButton {
  display: block;
  width: 100%;
  height: 80px;
  border: none;
  padding: 18px 28px;
  text-align: center;
}

.collegaButton {
  display: block;
  width: 100%;
  height: 80px;
  overflow: hidden;
}

.assigncol {
  min-width: 600px;
}

.collega-name {
  font-size: 15px;
}
