.KanbanEditForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.KanbanEditForm-input {
    width: 100%;
}

.KanbanEditForm-btn {
    font-size: 0.7rem;
    opacity: 0.8;
    padding: 0.2rem 0.4rem;
    border: 1px solid white;
    border-radius: 10px;
    cursor: pointer;
    margin-top: 5px;
}