.docentBox {
  border: 2px solid #000;
  margin: 5px;
  padding: 5px;
}

.assignButtonLine {
  height: 20px;
}

.assignmentVTE {
  font-size: 70%;
}