.KanbanTask {
  margin: 0.2rem 0;
  padding: 7px;
  height: min-content;
  min-width: 150px;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  border-radius: 5px;
  opacity: 0.9;
  text-align: left;
  overflow: hidden;
  font-size: 0.8rem;
}

.KanbanTask-content {
  padding: 1rem 0;
  font-size: 0.8rem;
}

.KanbanTask-options {
  font-size: 0.8rem;
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.KanbanTask-btn-edit,
.KanbanTask-btn-delete {
  font-size: 0.7rem;
  opacity: 0.8;
  padding: 0.2rem 0.4rem;
  border: 1px solid white;
  border-radius: 10px;
  cursor: pointer;
}