.Kanban {
  height: min-content;
  /*width: 90vw;*/
  padding: 1rem 2rem;
  max-height: 80vh;
}

.Kanban-columns-container {
  min-height: min-content;
  height: 100%;
  margin: 2rem 0;
  display: flex;
  justify-content: space-evenly;
  align-items: stretch;
}

.docenten-selector {
  width: 300px;
}
