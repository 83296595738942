@use "sass:color";

// general boostrap colors
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;
$blue: #0d6efd !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #d63384 !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #198754 !default;
$teal: #20c997 !default;
$cyan: #0dcaf0 !default;

// bootstrap colors
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-900 !default;

// modifed bootstrap colors
$primary: #f04c25;
$secondary: #575757;
$info: #009cab;
$adobepdf: #e60f00;
$msexcel: #10793F;
$msword: #1B5EBE;
$msppt: #C13B1B;

// modify bs modal z-index
$zindex-modal: 555;
$zindex-modal-backdrop: 550;
$zindex-fixed: 530;
// extra bootstrap colors
$primary-faded: color.adjust($primary, $lightness: 30%);

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
  "primary-faded": $primary-faded,
  "adobepdf": $adobepdf,
  "msexcel": $msexcel,
  "msword": $msword,
  "msppt": $msppt,
) !default;

