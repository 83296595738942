.surplus-studenten {
  vertical-align: middle;
  display: inline;
  input {
    display: inline-block;
    width: 100px;
    margin: 5px 10px;
  }
}

.ola-override-item{
  border: 1px solid rgba(0,0,0,.075);
  border-radius: 15px; 
  padding: 10px;
}

.pill{
  padding: 2px 7px;
  margin-right: 2px;
  border-radius: 99999px;
  background-color: #ddd;
}