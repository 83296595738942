.dropzone {
  text-align: center;
  background-color: rgb(226, 226, 226);
  width: 100%;
  height: 300px;
  border: 3px dashed rgb(0, 0, 0);
  display: flex;
  justify-content: center;
  flex-direction: column;
}
